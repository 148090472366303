/* variables */

:root {
    --shade-red: #DF4C73;
    --shade-white-2: #F8F8F8;
    --shade-white-3: #E1E1E1;
    --shade-grey-t1: rgba(51, 51, 51, .1);
    --shade-grey-t2: rgba(51, 51, 51, .2);
    --shade-grey-t3: rgba(51, 51, 51, .3);
    --shade-grey-t5: rgba(51, 51, 51, .5);
    --shade-grey-t9: rgba(51, 51, 51, .9);
    --shade-blue-t: rgba(163, 217, 237, .6);
    --shade-blue-1: #B4C8F2;
    --shade-blue-2: #98D6FF;
    --shade-blue-3: #C898FF;
    --shade-blue-4: #6E6DE2;
    --shade-pink-1: #CDBAE5;
    --shade-pink-2: #E6A9D7;
    --shade-pink-3: #FF98C8;
    --shade-orange-1: #F39D77;
    --shade-orange-2: #E8B895;
    --grad-blue: linear-gradient(60deg, var(--shade-blue-1) 0%, var(--shade-blue-2) 100%);
    --grad-pink: linear-gradient(-60deg, var(--shade-pink-2) 0%, var(--shade-pink-3) 100%);
    --grad-purple: linear-gradient(-60deg, var(--shade-blue-3) 0%, var(--shade-pink-1) 100%);
    --grad-orange: linear-gradient(-60deg, var(--shade-orange-1) 0%, var(--shade-orange-2) 100%);
    --intro-background: url(images/drawings/banners/Winson_Website_Banner_Compressed_2.png);
    --intro-background-mobile: url(images/drawings/banners/Winson_Website_Banner_Compressed_3.png);
}

/* fonts */

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Nanum Myeongjo";
    src: url("fonts/Nanum_Myeongjo/NanumMyeongjo-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Anton";
    src: url("fonts/Anton/Anton-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.App {}

/* assets */

.page {
    width: 100%;
    margin-top: 120px;
}

@media only screen and (max-width: 768px) {
    .page {
        margin-top: 80px;
    }
}

.body-container {
    width: 100%;
    padding: 40px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 1600px) {
    .body-container {
        padding: 20px 0px;
    }
}

.up-down {
    width: 100%;
    padding: 40px 0px;
    position: relative;
}

.up-down-unpad {
    padding: 0px 0px 40px;
}

.up-down-background {
    width: 100%;
    height: 1500px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), var(--grad-purple);
    position: absolute;
    z-index: -10;
    top: 65px;
}

.up-down-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1440px;
    margin: 0px auto;
}

.up-down-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    margin: 40px 0px;
    font-size: 64px;
    font-weight: bold;
}

.up-down-title > span {
    display: block;
}

.up-down-textdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.up-down-space {
    width: 100%;
    height: 50px;
}

@media only screen and (max-width: 1600px) {
    .up-down {
        padding: 20px 0px;
    }
    
    .up-down-unpad {
        padding: 0px 0px 20px;
    }
    
    .up-down-background {
        height: 750px;
        top: 30px;
    }
    
    .up-down-container {
        width: 75%;
    }
    
    .up-down-title {
        font-size: 48px;
    }
    
    .up-down-space {
        height: 25px;
    }
}

@media only screen and (max-width: 768px) {
    .up-down-container {
        width: 85%;
    }

    .up-down-title > span {
        display: none;
    }
}

.syllabus-intro {
    width: 100%;
    background-color: white;
    margin-bottom: 50px;
}

.syllabus-intro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1440px;
    margin: 0px auto;
}

.syllabus-intro-title {
    margin-top: 20px;
    color: black;
    font-size: 64px;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 1600px) {
    .syllabus-intro {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .syllabus-intro-container {
        width: 100%;
        margin: 0px auto;
    }
    
    .syllabus-intro-title {
        margin-top: 20px;
        font-size: 48px;
    }
}

@media only screen and (max-width: 768px) {
    .syllabus-intro-title {
        font-size: 48px;
    }

    .syllabus-intro-title > span {
        display: none;
    }
}

/* homepage */

.homepage {
    width: 100%;
    /* overflow: hidden; */
}

.homepage-intro {
    width: 100%;
    height: 100vh;
    /* background-color: black; */
    margin-bottom: 50px;
}

.homepage-bkg {
    width: 100%;
    height: 100vh;
    background: var(--grad-blue);
    position: absolute;
    z-index: -20;
}

.homepage-intro-background {
    width: 100%;
    height: 100vh;
    background: var(--intro-background) no-repeat center center fixed;
    background-size: cover;
    position: absolute;
    z-index: -10;
    transition:
        opacity 0.7s linear;
}

.homepage-fade-bkg {
    opacity: 0;
}

.homepage-fade-bkg.visible {
    opacity: 0.8;
}

.homepage-intro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    overflow: hidden;
}

.homepage-intro-textdiv {
    margin-top: -250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.homepage-intro-img {
    display: none;
    user-select: none;
}

.homepage-intro-img > img {
    width: 100%;
    height: 100%;
}

.homepage-text-shadow {
    text-shadow:
        -2px 1px 0 var(--shade-blue-4),
        -4px 2px 0 var(--shade-blue-4),
        -6px 3px 0 var(--shade-blue-4),
        -8px 4px 0 var(--shade-blue-4),
        -10px 5px 0 var(--shade-blue-4),
        -12px 6px 0 var(--shade-blue-4),
        -14px 7px 0 var(--shade-blue-4),
        -16px 8px 0 var(--shade-blue-4),
        -18px 9px 0 var(--shade-blue-4),
        -20px 10px 0 var(--shade-blue-4),
        -22px 11px 0 var(--shade-blue-4),
        -24px 12px 0 var(--shade-blue-4);
}

.homepage-text-shadow-2 {
    text-shadow:
        -2px 1px 0 var(--shade-blue-4),
        -4px 2px 0 var(--shade-blue-4),
        -6px 3px 0 var(--shade-blue-4),
        -8px 4px 0 var(--shade-blue-4);
}

.homepage-triangle {
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 800px 500px 600px;
    border-color: transparent transparent var(--shade-blue-t) transparent;
    top: -230px;
    bottom: 0;
    margin: auto 0 auto -50px;
    transform:
        rotate(335deg);
}

.homepage-rect-mobile {
    position: absolute;
    display: none;
}

.homepage-intro-title {
    color: var(--shade-white-2);
    font-size: 192px;
    font-family: "Nanum_Myeongjo";
}

.homepage-intro-title > span {
    font-size: 288px;
}

.homepage-intro-subtitle {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-left: -250px;
    color: var(--shade-white-2);
    font-size: 32px;
    font-family: "Nanum_Myeongjo";
}

.homepage-intro-fade {
    opacity: 0;
    transform: translateY(20px);
    transition:
        opacity 0.7s ease,
        transform 0.7s ease;
}

.homepage-intro-fade.visible {
    opacity: 1;
    transform: translateY(0);
}

.homepage-intro-fade.triangle {
    opacity: 1;
    transform:
        rotate(335deg)
        translateY(0);
}

.homepage-intro-fade.imgVisible {
    opacity: 1;
    transform: translateY(-50%);
}

.homepage-intro-booknow.hovered {
    background-color: var(--shade-grey-t5);
    transform: scale(1.05);
}

@media (hover: none) {
    .homepage-intro-background {
        background: transparent;
    }

    .homepage-intro-background:before {
        content: "";
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -10;
        background: var(--intro-background-mobile) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

@media only screen and (max-width: 1600px) {
    .homepage-intro-title {
        font-size: 144px;
    }

    .homepage-intro-title > span {
        font-size: 216px;
    }

    .homepage-intro-subtitle {
        margin-top: 0px;
        margin-left: -250px;
        font-size: 24px;
    }

    .homepage-triangle {
        border-width: 0 600px 400px 500px;
    }
}

@media only screen and (max-width: 1080px) {
    .homepage-intro-img {
        display: flex;
        width: 500px;
        height: 500px;
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
    }

    .homepage-intro-textdiv {
        margin-top: 600px;
    }

    .homepage-intro-title {
        font-size: 72px;
        font-family: "Anton";
    }

    .homepage-intro-title > span {
        font-size: 72px;
    }

    .homepage-intro-subtitle {
        font-family: "Lato";
        font-weight: normal;
        margin-top: 10px;
        margin-left: 0px;
        font-size: 24px;
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .homepage-text-shadow {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4),
            -4px 2px 0 var(--shade-blue-4);
    }

    .homepage-text-shadow-2 {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4),
            -4px 2px 0 var(--shade-blue-4);
    }

    .homepage-triangle {
        display: none;
    }

    .homepage-rect-mobile {
        background: radial-gradient(circle at 50% 35%, transparent 300px, var(--shade-blue-t) 100px);
        bottom: 0;
        margin: auto 0;
        width: 400px;
        height: 100%;
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .homepage-intro-img {
        width: 400px;
        height: 400px;
        top: 35%;
        transform: translateY(-50%);
    }

    .homepage-intro-textdiv {
        margin-top: 400px;
    }

    .homepage-intro-title {
        margin-top: 10px;
        font-size: 60px;
    }

    .homepage-intro-title > span {
        font-size: 60px;
    }

    .homepage-intro-subtitle {
        margin-top: 10px;
        margin-left: 0px;
        font-size: 18px;
        width: 300px;
    }

    .homepage-text-shadow {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-text-shadow-2 {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-rect-mobile {
        background: radial-gradient(circle at 50% 35%, transparent 250px, var(--shade-blue-t) 100px);
        width: 300px;
    }
}

@media only screen and (max-height: 900px) and (max-width: 1080px) {
    .homepage-intro-img {
        width: 300px;
        height: 300px;
        top: 35%;
        transform: translateY(-50%);
    }

    .homepage-intro-textdiv {
        margin-top: 400px;
    }

    .homepage-intro-title {
        margin-top: 10px;
        font-size: 60px;
    }

    .homepage-intro-title > span {
        font-size: 60px;
    }

    .homepage-intro-subtitle {
        margin-top: 10px;
        margin-left: 0px;
        font-size: 18px;
        width: 300px;
    }

    .homepage-text-shadow {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-text-shadow-2 {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-rect-mobile {
        background: radial-gradient(circle at 50% 35%, transparent 200px, var(--shade-blue-t) 100px);
        width: 300px;
    }
}

@media only screen and (max-height: 600px) and (max-width: 1080px) {
    .homepage-intro-img {
        display: none;
    }

    .homepage-intro-textdiv {
        margin-top: 0px;
    }

    .homepage-intro-title {
        margin-top: 10px;
        font-size: 60px;
    }

    .homepage-intro-title > span {
        font-size: 60px;
    }

    .homepage-intro-subtitle {
        margin-top: 10px;
        margin-left: 0px;
        font-size: 18px;
        width: 300px;
    }

    .homepage-text-shadow {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-text-shadow-2 {
        text-shadow:
            -2px 1px 0 var(--shade-blue-4);
    }

    .homepage-rect-mobile {
        background: var(--shade-blue-t);
        width: 300px;
    }
}

.counter-wrapper {
    margin-top: 125px;
    margin-bottom: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Nanum Myeongjo";
    font-weight: bold;
    color: var(--shade-white-2);
    font-size: 40px;
    /* text-shadow:
        -1px 1px 0 var(--shade-pink-2),
        1px 1px 0 var(--shade-red),
        1px -1px 0 var(--shade-blue-2),
        -1px -1px 0 var(--shade-blue-3); */
    z-index: 100;
}

@media only screen and (max-width: 1600px) {
    .counter-wrapper {
        margin-top: 110px;
        margin-bottom: -40px;
        font-size: 28px;
        /* text-shadow:
            -0.5px 0.5px 0 var(--shade-pink-2),
            0.5px 0.5px 0 var(--shade-red),
            0.5px -0.5px 0 var(--shade-blue-2),
            -0.5px -0.5px 0 var(--shade-blue-3); */
    }
}

@media only screen and (max-width: 1080px) {
    .counter-wrapper {
        font-family: "Lato";
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 24px;
    }
}

/* @media only screen and (max-width: 768px) {
    .counter-wrapper {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 24px;
    }
} */

.shelf-banner-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 1440px;
    padding: 0px 25px;
    box-sizing: border-box;
}

.shelf-wrapper {
    width: 600px;
    height: 600px;
    position: relative;
}

.shelf-wrapper > div {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: 25px;
    padding: 25px;
    position: absolute;
    background-color: var(--shade-white-2);
    transition: all 0.3s ease-in-out;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.shelf-wrapper > div.hovered {
    width: 500px;
    z-index: 1;
    background-color: var(--shade-pink-1);
}

.shelf-wrapper > div.hidden {
    opacity: 0;
}

.shelf-wrapper > div:nth-child(2).hidden {
    opacity: 1;
}

.shelf-wrapper > div:nth-child(1) {
    left: 0px;
    background-color: var(--shade-pink-3);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shelf-wrapper > div:nth-child(2) {
    right: 0px;
}

.shelf-wrapper > div:nth-child(3) {
    left: 0px;
    top: 300px;
}

.shelf-wrapper > div:nth-child(4) {
    right: 0px;
    top: 300px;
}

.shelf-wrapper > div:nth-child(1) > div {
    font-size: 36px;
    color: var(--shade-white-2);
    text-align: left;
}

.shelf-wrapper > div:nth-child(1).hovered {
    width: 200px;
}

.shelf-wrapper > div:nth-child(1).hovered > div > b {
    font-size: 36px;
    color: var(--shade-white-2);
    text-align: left;
}

.shelf-decor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    height: 200px;
}

.shelf-decor > hr:nth-child(1) {
    width: 180px;
    border: 2px solid var(--shade-white-2);
    transition: all 0.3s ease-in-out;
}

.shelf-decor > hr:nth-child(2) {
    width: 180px;
    border: 2px solid var(--shade-white-2);
    transition: all 0.3s ease-in-out;
}

.shelf-text {
    opacity: 1;
    color: black;
    text-align: right;
    font-size: 28px;
    font-family: "Lato";
    transition: all 0.3s ease-in-out;
}

.shelf-text span {
    display: none;
}

.shelf-text.hovered span {
    display: contents;
}

.shelf-text b {
    font-weight: bold;
}

.shelf-text.hovered > b {
    color: var(--shade-red);
}

.shelf-text.hidden {
    opacity: 0;
}

.shelf-wrapper > div:nth-child(2) > div.hidden {
    opacity: 1;
}

.shelf-text div {
    display: contents;
}

.shelf-text.hovered div {
    display: none;
}

.shelf-block-image {
    width: 750px;
    height: 600px;
    text-align: center;
}

.shelf-block-image > img {
    width: 575px;
    height: 575px;
}

.bannerlist-wrapper {
    margin-top: 25px;
    width: 1440px;
    height: 300px;
    /* border: 3px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.bannerlist-left {
    width: 600px;
    display: flex;
    justify-content: center;
    /* border: 3px solid red; */
}

.bannerlist-right {
    width: 750px;
    display: flex;
    justify-content: center;
    /* border: 3px solid red; */
}

.bannerlist-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 200px;
    margin: 0px 25px;
    padding: 25px;
    background-color: var(--shade-white-2);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    transition: all 0.2s ease-in-out;
}

.bannerlist-left > div:nth-child(1) {
    width: 500px;
    height: 200px;
}

.bannerlist-left > div:nth-child(1) > div {
    opacity: 1;
    width: 500px;
}

.bannerlist-title {
    width: 150px;
    opacity: 1;
    color: black;
    font-size: 32px;
    font-family: "Lato";
}

.bannerlist-title.hovered {
    color: var(--shade-red);
}

.bannerlist-text {
    width: 150px;
    opacity: 1;
    color: black;
    font-size: 18px;
    font-family: "Lato";
    margin-top: 20px;
}

.bannerlist-text.hovered > b {
    color: var(--shade-red);
}

@media only screen and (max-width: 1600px) {
    .shelf-banner-wrapper {
        width: 75%;
    }

    .shelf-wrapper {
        width: 460px;
        height: 460px;
    }

    .shelf-wrapper > div {
        width: 150px;
        height: 150px;
        margin: 20px;
        padding: 20px;
    }

    .shelf-wrapper > div:nth-child(2).hidden {
        opacity: 1;
    }

    .shelf-wrapper > div.hovered {
        width: 380px;
    }

    .shelf-wrapper > div:nth-child(3) {
        left: 0px;
        top: 230px;
    }
    
    .shelf-wrapper > div:nth-child(4) {
        right: 0px;
        top: 230px;
    }

    .shelf-wrapper > div:nth-child(1) > div {
        font-size: 24px;
    }

    .shelf-wrapper > div:nth-child(1).hovered {
        width: 150px;
    }
    
    .shelf-wrapper > div:nth-child(1).hovered > div > b {
        font-size: 24px;
    }
    
    .shelf-decor {
        height: 150px;
    }
    
    .shelf-decor > hr:nth-child(1) {
        width: 120px;
    }
    
    .shelf-decor > hr:nth-child(2) {
        width: 120px;
    }
    
    .shelf-text {
        font-size: 18px;
    }

    .shelf-block-image {
        width: 460px;
        height: 460px;
    }
    
    .shelf-block-image > img {
        width: 440px;
        height: 440px;
    }

    .bannerlist-wrapper {
        margin-top: 20px;
        width: 100%;
        height: 200px;
    }
    
    .bannerlist-left {
        width: 460px;
    }
    
    .bannerlist-right {
        width: 460px;
    }

    .bannerlist-banner {
        width: 90px;
        height: 150px;
        margin: 0px 15px;
        padding: 20px;
    }

    .bannerlist-left > div:nth-child(1) {
        width: 380px;
        height: 150px;
    }

    .bannerlist-left > div:nth-child(1) > div {
        width: 380px;
    }

    .bannerlist-title {
        width: 100px;
        font-size: 20px;
    }

    .bannerlist-text {
        width: 100px;
        font-size: 12px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 1300px) {
    .shelf-banner-wrapper {
        width: 75%;
        height: 920px;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .shelf-wrapper {
        width: 230px;
        height: 920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .shelf-wrapper > div {
        width: 150px;
        height: 150px;
        margin: 20px;
        padding: 20px;
        position: relative;
    }

    .shelf-wrapper > div.hidden {
        opacity: 1;
    }

    .shelf-wrapper > div.hidden > div {
        opacity: 1;
    }

    .shelf-wrapper > div:nth-child(2).hidden {
        opacity: 1;
    }

    .shelf-wrapper > div.hovered {
        width: 150px;
        height: 300px;
    }

    .shelf-wrapper > div:nth-child(3) {
        top: 0px;
    }
    
    .shelf-wrapper > div:nth-child(4) {
        top: 0px;
    }

    .shelf-wrapper > div:nth-child(1) > div {
        font-size: 24px;
    }

    .shelf-wrapper > div:nth-child(1).hovered {
        width: 150px;
        height: 150px;
    }
    
    .shelf-wrapper > div:nth-child(1).hovered > div > b {
        font-size: 24px;
    }
    
    .shelf-decor {
        height: 150px;
    }
    
    .shelf-decor > hr:nth-child(1) {
        width: 120px;
    }
    
    .shelf-decor > hr:nth-child(2) {
        width: 120px;
    }
    
    .shelf-text {
        font-size: 18px;
    }

    .shelf-block-image {
        width: 460px;
        height: 460px;
    }
    
    .shelf-block-image > img {
        width: 100%;
        height: auto;
    }

    .bannerlist-wrapper {
        margin-top: 0px;
        width: 460px;
        height: 460px;
        flex-direction: column;
        align-items: center;
    }
    
    .bannerlist-left {
        width: 420px;
    }
    
    .bannerlist-right {
        width: 420px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bannerlist-banner {
        width: 90px;
        height: 150px;
        margin: 0px;
        padding: 20px;
    }

    .bannerlist-left > div:nth-child(1) {
        width: 380px;
        height: 150px;
    }

    .bannerlist-left > div:nth-child(1) > div {
        width: 380px;
    }

    .bannerlist-title {
        width: 100px;
        font-size: 20px;
    }

    .bannerlist-text {
        width: 100px;
        font-size: 12px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 990px) {
    .shelf-banner-wrapper {
        width: 75%;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }

    .shelf-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .shelf-wrapper > div {
        width: 100%;
        height: 50px;
        margin: 20px;
        padding: 20px;
        position: relative;
    }

    .shelf-wrapper > div.hidden {
        opacity: 1;
    }

    .shelf-wrapper > div.hidden > div {
        opacity: 1;
    }

    .shelf-wrapper > div.hovered {
        width: 100%;
        height: 50px;
    }

    .shelf-wrapper > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shelf-wrapper > div:nth-child(1) > div {
        font-size: 24px;
    }

    .shelf-wrapper > div:nth-child(1).hovered {
        width: 100%;
        height: 50px;
    }
    
    .shelf-wrapper > div:nth-child(1).hovered > div > b {
        font-size: 24px;
    }
    
    .shelf-decor {
        width: 90%;
        height: 80px;
    }
    
    .shelf-decor > hr:nth-child(1) {
        width: 100%;
        border: 1px solid white;
    }
    
    .shelf-decor > hr:nth-child(2) {
        width: 100%;
        border: 1px solid white;
    }
    
    .shelf-text {
        font-size: 18px;
    }

    .shelf-text span {
        display: contents;
    }

    .shelf-text b {
        font-weight: bold;
    }

    .shelf-text.hovered > b {
        color: var(--shade-red);
    }

    .shelf-text div {
        display: none;
    }

    .shelf-block-image {
        width: 100%;
        height: auto;
    }
    
    .shelf-block-image > img {
        width: 100%;
        height: auto;
    }

    .bannerlist-wrapper {
        margin-top: 0px;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .bannerlist-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .bannerlist-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .bannerlist-banner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: auto;
        margin: 10px 0px;
        padding: 25px;
        box-sizing: border-box;
    }

    .bannerlist-left > div:nth-child(1) {
        width: 100%;
        height: auto;
    }

    .bannerlist-left > div:nth-child(1) > div {
        width: 100%;
    }

    .bannerlist-title {
        width: 100%;
        font-size: 25px;
    }

    .bannerlist-text {
        width: 100%;
        font-size: 18px;
        margin-top: 16px;
    }

    .bannerlist-text > br {
        display: none;
    }
}

.carousel {
    width: 1440px;
    height: auto;
}

.carousel-wrapper {
    width: 1440px;
    height: auto;
    display: flex;
    position: relative;
    background-color: white;
    user-select: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 96px;
    color: grey;
    z-index: 10;
    position: absolute;
    width: 150px;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

.carousel-left > img {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    transform: rotateZ(-90deg)
}

.carousel-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 96px;
    color: grey;
    z-index: 10;
    position: absolute;
    width: 150px;
    height: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

.carousel-right > img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transform: rotateZ(90deg)
}

.carousel-left.hovered {
    cursor: pointer;
    background: var(--shade-grey-t1);
}

.carousel-right.hovered {
    cursor: pointer;
    background: var(--shade-grey-t1);
}

.carousel-dot-container {
    display: flex;
    z-index: 10;
    position: absolute;
    width: 90px;
    height: 30px;
    margin-bottom: 10px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.carousel-dot {
    background: var(--shade-grey-t1);
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 10px;
}

.carousel-dot.active {
    background: var(--shade-grey-t2);
}

.carousel-image-wrapper {
    width: 1440px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.carousel-image-wrapper > :nth-child(1) {
    cursor: pointer;
}

.carousel-image-wrapper > :nth-child(3) {
    cursor: pointer;
}

.carousel-image {
    flex-shrink: 0;
    width: 1440px;
    height: auto;
    line-height: 0;
    transition: all 0.3s ease-in-out;
}

.carousel-image > img {
    width: 1440px;
    height: auto;
}

@media only screen and (max-width: 1600px) {
    .carousel {
        width: 100%;
    }
    
    .carousel-wrapper {
        width: 100%;
    }
    
    .carousel-left {
        font-size: 64px;
        width: 100px;
    }
    
    .carousel-left > img {
        margin-left: 15px;
        width: 20px;
        height: 20px;
    }
    
    .carousel-right {
        font-size: 64px;
        width: 100px;
    }
    
    .carousel-right > img {
        margin-right: 15px;
        width: 20px;
        height: 20px;
    }
    
    .carousel-image-wrapper {
        width: 100%;
    }
    
    .carousel-image {
        width: 100%;
    }
    
    .carousel-image > img {
        width: 100%;
    }
}

.pricing {
    width: 1440px;
    height: auto;
}

.pricing-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 1440px;
    gap: 10px;
}

.pricing-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 1000px;
    background: var(--shade-white-2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pricing-title {
    width: 100%;
    color: var(--shade-pink-1);
    font-size: 28px;
    font-family: "Lato";
    text-align: center;
    margin-top: 40px;
}

.pricing-price {
    width: 100%;
    color: var(--shade-pink-1);
    font-size: 60px;
    font-family: "Lato";
    text-align: center;
    margin: 20px 0px;
}

.pricing-price > span {
    color: black;
    font-size: 12px;
    margin: 0px 5px;
}

.pricing-price > span:first-child {
    vertical-align: super;
}

.pricing-highlight {
    color: var(--shade-pink-3);
}

.pricing-highlight > span {
    color: black;
}

.pricing-offers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 80%;
    margin: 10px 0px;
}

.pricing-offers-text {
    width: 320px;
}

.pricing-offers-check {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pricing-offers-check > img {
    width: 30px;
    height: 30px;
}

.pricing-button {
    border: 2px solid var(--shade-pink-1);
    padding: 20px 100px;
    font-size: 24px;
    color: black;
    white-space: nowrap;
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.pricing-button-highlight {
    color: white;
    border: 2px solid var(--shade-pink-3);
    background-color: var(--shade-pink-3);
}

.pricing-left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: grey;
    z-index: 10;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    left: 0;
    top: 100px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.pricing-left > img {
    width: 20px;
    height: 20px;
    transform: rotateZ(-90deg);
}

.pricing-right {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: grey;
    z-index: 10;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    right: 0;
    top: 100px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.pricing-right > img {
    width: 20px;
    height: 20px;
    transform: rotateZ(90deg);
}

.pricing-left.hovered {
    cursor: pointer;
    background: var(--shade-grey-t1);
}

.pricing-right.hovered {
    cursor: pointer;
    background: var(--shade-grey-t1);
}


@media only screen and (max-width: 1600px) {
    .pricing {
        width: 100%;
    }
    
    .pricing-wrapper {
        width: 100%;
    }

    .pricing-button {
        padding: 10px 50px;
        font-size: 20px;
    }

    .pricing-offers-text {
        width: 100%;
    }
    
    .pricing-offers-check {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    .pricing-offers-check > img {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 1300px) {
    .pricing-box {
        height: 1100px;
    }
}

@media only screen and (max-width: 1080px) {
    .pricing-wrapper {
        height: 1100px;
    }

    .pricing-box {
        position: absolute;
        opacity: 0;
    }

    .pricing-wrapper > div.active {
        opacity: 1;
    }

    .pricing-left {
        opacity: 1;
    }

    .pricing-right {
        opacity: 1;
    }
}

.reviews {
    width: 1440px;
}

.reviews-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 1440px;
}

.reviews-box-wrapper {
    --gap: 10px;
    --columns: 3;
    width: 1440px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--gap);
    position: relative;
    user-select: none;
}

.reviews-box {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
    max-height: 800px;
    padding: 25px;
    box-sizing: border-box;
    background-color: var(--shade-white-2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.3s ease-in-out;
}

.reviews-description {
    color: black;
    font-size: 16px;
    font-family: "Lato";
    line-height: 1.5em;
}

.reviews-name {
    margin: 20px 0px 0px;
    color: black;
    font-size: 24px;
    font-family: "Lato";
    text-align: center;
}

.reviews-uni {
    margin: 5px 0px 0px;
    color: black;
    font-size: 14px;
    font-family: "Lato";
    text-align: left;
}

@media only screen and (max-width: 1600px) {
    .reviews {
        width: 100%;
    }
    
    .reviews-wrapper {
        width: 100%;
    }
    
    .reviews-box-wrapper {
        --gap: 10px;
        --columns: 2;
        width: 100%;
    }
    
    .reviews-box {
        max-height: 800px;
        padding: 25px;
    }
    
    .reviews-description {
        font-size: 14px;
    }
    
    .reviews-name {
        font-size: 22px;
    }

    .reviews-uni {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1080px) {
    .reviews-box-wrapper {
        --gap: 10px;
        --columns: 1;
        width: 100%;
    }
}

.signature-reviews-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 1440px;
    user-select: none;
    gap: 10px;
}

.signature-reviews-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    max-height: 800px;
    padding: 25px;
    box-sizing: border-box;
    background-color: var(--shade-white-2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 1600px) {
    .signature-reviews-wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .signature-reviews-wrapper {
        flex-direction: column;
    }
}

.rotlist-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rotlist-point {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 250px;
    margin: 100px;
    padding: 25px;
    box-sizing: border-box;
    background-color: white;
    transform: rotateZ(45deg);
    transition: all 0.2s ease-in-out;
}

.rotlist-empty {
    background-color: transparent;
}

.rotlist-point-shade {
    width: 5px;
    height: 250px;
    position: absolute;
    background-color: var(--shade-orange-2);
    margin-left: -25px;
    transform-origin: left center;
    transition: all 0.2s ease-in-out;
}

.rotlist-point-shade.hovered {
    transform:
        scaleX(50);
}

.rotlist-point-text {
    text-align: center;
    color: black;
    font-size: 24px;
    z-index: 10;
    transform: rotate(-45deg);
    transition: all 0.2s ease-in-out;
}

.rotlist-point-text > b {
    font-size: 24px;
}

.rotlist-point-text.hovered {
    color: white;
}

.rotlist-point-text.hovered > b {
    color: var(--shade-red);
}

@media only screen and (max-width: 1600px) {
    .rotlist-point {
        width: 200px;
        height: 200px;
        margin: 100px;
        padding: 25px;
    }
    
    .rotlist-point-shade {
        width: 5px;
        height: 200px;
    }
    
    .rotlist-point-shade.hovered {
        transform:
            scaleX(40);
    }
    
    .rotlist-point-text {
        font-size: 20px;
    }
    
    .rotlist-point-text > b {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .rotlist-point {
        margin: 75px;
    }
}

@media only screen and (max-width: 1080px) {
    .rotlist-wrapper {
        display: flex;
        flex-direction: column;
        margin: 50px 0px;
        width: 100%;
    }
    
    .rotlist-point {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 500px;
        height: 100px;
        margin: 20px;
        padding: 25px;
        box-sizing: border-box;
        background-color: white;
        transform: rotateZ(0deg);
        transition: all 0.2s ease-in-out;
    }
    
    .rotlist-empty {
        background-color: transparent;
    }
    
    .rotlist-point-shade {
        width: 5px;
        height: 100px;
        position: absolute;
        background-color: var(--shade-orange-2);
        margin-left: -25px;
        transform-origin: left center;
        transition: all 0.2s ease-in-out;
    }
    
    .rotlist-point-shade.hovered {
        transform:
            scaleX(100);
    }
    
    .rotlist-point-text {
        text-align: left;
        color: black;
        font-size: 20px;
        z-index: 10;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
    }
    
    .rotlist-point-text > b {
        font-size: 20px;
    }
    
    .rotlist-point-text.hovered {
        color: white;
    }
    
    .rotlist-point-text.hovered > b {
        color: var(--shade-red);
    }
}

@media only screen and (max-width: 768px) {
    .rotlist-point {
        width: 90%;
    }

    .rotlist-point-shade {
        display: none;
    }
    
    .rotlist-point-text.hovered {
        color: black;
    }
    
    .rotlist-point-text.hovered > b {
        color: var(--shade-red);
    }
}

/* 
.list-wrapper {
    display: flex;
    flex-direction: column;
}

.list-point {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    height: 80px;
    margin: 10px 0px;
    background-color: white;
    transition: all 0.2s ease-in-out;
}

.list-point-text {
    width: 550px;
    margin-right: 25px;
    color: black;
    text-align: left;
    font-size: 20px;
    z-index: 10;
    transition: all 0.2s ease-in-out;
}

.list-point-text.hovered {
    color: white;
}

.list-point-text.hovered > b {
    color: var(--shade-pink-3);
}

.list-point-invert.hovered > div > b {
    color: var(--shade-blue-3);
}

.list-text-rev {
    margin-left: 25px;
    margin-right: 0px;
    text-align: right;
}

.list-point-shade {
    width: 8px;
    height: 80px;
    background-color: var(--shade-pink-1);
    transition: all 0.2s ease-in-out;
    transform-origin: left center;
}

.list-point-shade.hovered {
    transform: scaleX(75);
}

.list-shade-rev {
    transform-origin: right center;
}

.list-point-invert {
    background-color: white;
}

.list-shade-invert {
    background-color: var(--shade-blue-1);
} */

.info-tabs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-tab-titles-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 75px;
    position: relative;
    /* border: 3px solid red; */
    transition: all 0.2s ease-in-out;
}

.info-tab-titles-wrapper > div:nth-child(1) {
    position: absolute;
    width: calc(100% / 2);
    height: 5px;
    background-color: var(--shade-red);
    /* border: 5px solid var(--shade-red); */
    z-index: 10;
    transition: all 0.2s ease-in-out;
}

.info-tab-titles-wrapper > div:nth-child(1).tab1-active {
    transform: translateX(-50%);
}

.info-tab-titles-wrapper > div:nth-child(1).tab2-active {
    transform: translateX(50%);
}

.info-tab-title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-grey-t2);
    font-size: 24px;
    transition: all 0.2s ease-in-out;
}

.info-tab-titles-wrapper > div:nth-child(2) {
    border-radius: 30px 0px 0px 0px;
}

.info-tab-titles-wrapper > div:nth-child(3) {
    border-radius: 0px 30px 0px 0px;
}

.info-tab-title.hovered {
    cursor: pointer;
    color: white;
    background-color: var(--shade-grey-t5);
}

.info-tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.info-tab.info-tab-0 {
    height: 425px;
}

.info-tab.info-tab-1 {
    height: 625px;
}

.info-tab-text {
    z-index: -10;
    opacity: 0;
    width: 100%;
    background-color: var(--shade-grey-t1);
    border-radius: 0px 0px 30px 30px;
    font-size: 24px;
    text-align: left;
    position: absolute;
    transition: all 0.2s ease-in-out;
}

.info-tab-text.active {
    z-index: 10;
    opacity: 1;
}

.info-tab-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: minmax(100px, auto);
    margin: 40px;
    grid-auto-flow: dense;
    gap: 10px;
}

.info-tab-grid-item {
    background-color: var(--shade-grey-t2);
    place-items: center;
    padding: 20px;
}

.info-tab-grid-0 > div {
    text-align: center;
}

.info-tab-grid-item.hovered {
    cursor: pointer;
}

.info-tab-grid-item.hovered > b {
    color: var(--shade-red);
}

.info-tab-grid-0 > div:nth-child(1) {
    grid-column: span 3;
    grid-row: span 1;
}

.info-tab-grid-0 > div:nth-child(2) {
    grid-column: span 1;
    grid-row: span 2;
}

.info-tab-grid-0 > div:nth-child(5) {
    grid-column: span 4;
    grid-row: span 1;
}

.info-tab-grid-1 > div:nth-child(1) {
    grid-column: span 2;
    grid-row: span 1;
}

.info-tab-grid-1 > div:nth-child(2) {
    grid-column: span 1;
    grid-row: span 2;
}

.info-tab-grid-1 > div:nth-child(4) {
    grid-column: span 1;
    grid-row: span 2;
}

.info-tab-grid-1 > div:nth-child(5) {
    grid-column: span 2;
    grid-row: span 1;
}

.info-tab-grid-1 > div:nth-child(8) {
    grid-column: span 4;
    grid-row: span 1;
}

@media only screen and (max-width: 1600px) {
    .info-tab-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: minmax(100px, auto);
    }
}

@media only screen and (max-width: 1360px) {
    .info-tab-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: minmax(100px, auto);
    }
}

@media only screen and (max-width: 1160px) {
    .info-tab-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-template-rows: minmax(100px, auto);
    }
}

@media only screen and (max-width: 1080px) {
    .info-tab-titles-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        height: 150px;
        position: relative;
        /* border: 3px solid red; */
        transition: all 0.2s ease-in-out;
    }
    
    .info-tab-titles-wrapper > div:nth-child(1) {
        position: absolute;
        left: 0;
        width: 5px;
        height: 75px;
        background-color: var(--shade-red);
        /* border: 5px solid var(--shade-red); */
        z-index: 10;
        transform: rotateZ(90deg);
        transition: all 0.2s ease-in-out;
    }
    
    .info-tab-titles-wrapper > div:nth-child(1).tab1-active {
        transform:
        translateY(-75px)
    }
    
    .info-tab-titles-wrapper > div:nth-child(1).tab2-active {
        transform:
            translateY(0px)
    }
    
    .info-tab-title {
        height: 75px;
        font-size: 24px;
    }
    
    .info-tab-titles-wrapper > div:nth-child(2) {
        border-radius: 0px 30px 0px 0px;
    }
    
    .info-tab-titles-wrapper > div:nth-child(3) {
        border-radius: 0px 0px 0px 0px;
    }

    .info-tab {
        width: 100%;
    }

    .info-tab.info-tab-0 {
        height: 900px;
    }
    
    .info-tab.info-tab-1 {
        height: 1800px;
    }
    
    .info-tab-text {
        width: 100%;
        border-radius: 0px 0px 30px 30px;
        font-size: 24px;
    }
    
    .info-tab-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: minmax(100px, auto);
        margin: 40px;
        gap: 10px;
    }
    
    .info-tab-grid-item {
        padding: 20px;
    }

    .info-tab-grid-0 > div:nth-child(1) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-0 > div:nth-child(2) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-0 > div:nth-child(5) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-1 > div:nth-child(1) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-1 > div:nth-child(2) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-1 > div:nth-child(4) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-1 > div:nth-child(5) {
        grid-column: span 1;
        grid-row: span 1;
    }
    
    .info-tab-grid-1 > div:nth-child(8) {
        grid-column: span 1;
        grid-row: span 1;
    }
}

.exam-wrapper {
    color: black;
    font-size: 20px;
}

.exam-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1440px;
    margin: 20px 0px;
}

.exam-catagory {
    margin: 20px 0px;
    font-size: 32px;
}

.exam-grid-numbers {
    width: 1440px;
    display: flex;
    margin: 5px 0px;
    font-size: 24px;
    justify-content: flex-start;
}

.exam-grid-numbers > div {
    width: calc(100% / 7);
    margin: auto;
    text-align: left;
}

.exam-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* grid-template-rows: repeat(6, 1fr); */
    grid-gap: 10px;
    width: 1440px;
    /* height: 600px; */
    background-color: var(--shade-white-2);
}

.exam-row {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--shade-white-3);
    grid-column-start: 1;
    grid-column-end: 8;
    cursor: pointer;
    box-shadow: -5px 5px 2.5px var(--shade-grey-t2);
    transition: all 0.2s ease-in-out;
}

.exam-row > span {
    display: block;
}

.exam-row > div {
    display: none;
}

.exam-row.hovered {
    transform: scale(1.1);
}

.exam-row::after {
    content: "";
    position: absolute;
}

.exam-row::before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid var(--shade-white-3);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.exam-row-highlight {
    background-color: var(--shade-red);
}

.exam-row-highlight::before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid var(--shade-red);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.exam-row-description {
    position: absolute;
    z-index: 100;
    width: 500px;
    border-radius: 30px;
    padding: 40px 20px;
    box-sizing: border-box;
    background-color: var(--shade-grey-t5);
    color: white;
    box-shadow: -5px 5px 2.5px var(--shade-grey-t2);
    opacity: 0;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
}

.exam-row-description.hovered {
    opacity: 1;
}

.exam-row-start-1 {
    grid-column-start: 1;
}
.exam-row-start-2 {
    grid-column-start: 2;
}
.exam-row-start-3 {
    grid-column-start: 3;
}
.exam-row-start-4 {
    grid-column-start: 4;
}
.exam-row-start-5 {
    grid-column-start: 5;
}
.exam-row-start-6 {
    grid-column-start: 6;
}
.exam-row-start-7 {
    grid-column-start: 7;
}

.exam-row-end-2 {
    grid-column-end: 2;
}
.exam-row-end-3 {
    grid-column-end: 3;
}
.exam-row-end-4 {
    grid-column-end: 4;
}
.exam-row-end-5 {
    grid-column-end: 5;
}
.exam-row-end-6 {
    grid-column-end: 6;
}
.exam-row-end-7 {
    grid-column-end: 7;
}
.exam-row-end-8 {
    grid-column-end: 8;
}

@media only screen and (max-width: 1600px) {
    .exam-wrapper {
        font-size: 18px;
    }
    
    .exam-table {
        width: 100%;
        margin: 20px 0px;
    }
    
    .exam-catagory {
        margin: 20px 0px;
        font-size: 24px;
    }
    
    .exam-grid-numbers {
        width: 100%;
        margin: 5px 0px;
        font-size: 24px;
    }
    
    .exam-grid {
        grid-gap: 10px;
        width: 100%;
    }
    
    .exam-row {
        width: 100%;
        padding: 20px;
        box-shadow: -5px 5px 2.5px var(--shade-grey-t2);
    }

    .exam-row-description {
        width: 400px;
        border-radius: 30px;
        padding: 40px 20px;
        box-shadow: -5px 5px 2.5px var(--shade-grey-t2);
    }
}

@media only screen and (max-width: 1080px) {
    .exam-row > span {
        display: none;
    }

    .exam-row > div {
        display: inline-block;
    }
}

@media only screen and (max-width: 768px) {
    .exam-row-description {
        width: 100%;
        border-radius: 30px;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: -5px 5px 2.5px var(--shade-grey-t2);
        opacity: 0;
        left: 0px;
        top: -30px;
        transition: all 0.2s ease-in-out;
    }
}

.students-from {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.students-from > li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    width: 33%;
    margin: 30px 0px;
}

@media only screen and (max-width: 1600px) {
    .students-from > li {
        width: 50%;
    }
}

@media only screen and (max-width: 1200px) {
    .students-from > li {
        width: 100%;
    }
}

/* header */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 500;
    opacity: 1;
    transition:
        opacity 0.7s linear,
        top 0.3s,
        background-color 0.3s,
        box-shadow 0.3s;
}

.header-fade {
    opacity: 0;
}

.header-fade.visible {
    opacity: 1;
}

.header.transparent {
    background: var(--shade-grey-t1);
    box-shadow: none;
}

/* slightly transparent fallback */
.header.transparent {
    background-color: rgba(255, 255, 255, .5);
}
  
/* if backdrop support: very transparent and blurred */
/* @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .header.transparent {
        background-color: rgba(255, 255, 255, .2);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
} */

.header-container {
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    transition: all 0.3s ease-in-out
}

.header-container.open {
    height: 100%;
    flex-wrap: wrap;
    background-color: white;
}

.header-winson-icon {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 20px;
    padding: 0px 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.header-winson-icon > img {
    width: 60px;
    height: 60px;
    padding: 10px;
}

.header-links {
    display: flex;
    justify-content: flex-end;
}

.header-links.open {
    width: 100%;
    flex-wrap: wrap;
}

.header-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 200px;
    height: 80px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-family: "Lato";
    transition:
        background-color 0.3s,
        color 0.3s;
}

.header-link.open {
    width: 100%;
}

.header-link.hovered {
    background-color: black;
    color: white;
}

@media only screen and (max-width: 1600px) {
    .header-winson-icon {
        font-size: 15px;
        font-family: "Lato";
        padding: 0px 15px;
    }
    
    .header-winson-icon > img {
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    .header-container {
        width: 100%;
    }

    .header-link {
        font-size: 15px;
        width: 150px;
        height: 60px;
    }
}

@media only screen and (max-height: 1200px) {
    .header-winson-icon {
        font-size: 15px;
        font-family: "Lato";
        padding: 0px 15px;
    }
    
    .header-winson-icon > img {
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    .header-container {
        width: 100%;
    }

    .header-link {
        font-size: 15px;
        width: 150px;
        height: 60px;
    }
}

@media only screen and (max-width: 1080px) {
    .header-winson-icon {
        font-size: 15px;
        font-family: "Lato";
        padding: 0px 15px;
    }
    
    .header-winson-icon > img {
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    .header-container {
        width: 100%;
    }

    .header-link {
        font-size: 15px;
        width: 120px;
        height: 60px;
    }
}

/* footer */

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--shade-blue-1);
    user-select: none;
    transition: bottom 0.3s ease-in-out;
}

.footer-container {
    width: 1440px;
    display: flex;
    flex-direction: column;
    background-color: var(--shade-blue-1);
    user-select: none;
    transition: bottom 0.3s ease-in-out;
}

.footer-title {
    margin-top: 20px;
    font-family: "Lato";
    font-size: 30px;
}

.footer-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 80px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-family: "Lato";
}

@media only screen and (max-width: 1600px) {
    .footer-container {
        width: 75%;
    }

    .footer-links {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 900px) {
    .footer-body {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-links {
        flex-direction: column;
    }

    .footer-link {
        height: 60px;
        justify-content: flex-start;
    }
}

/* fixed-footer */

.fixed-footer {
    width: 100%;
    user-select: none;
}

.fixed-footer-share {
    z-index: 500;
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    transition:
        transform 0.3s ease-in-out;
}

.fixed-footer-signup {
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    transition:
        transform 0.3s ease-in-out;
}

.fixed-footer-signup.hovered {
    transform: scale(1.1);
}

.fixed-footer-signup > img {
    /* width: 100px; */
    width: 250px;
    height: auto;
}

@media only screen and (max-width: 1600px) {
    .fixed-footer-signup > img {
        /* width: 100px; */
        width: 150px;
        height: auto;
    }
}

@media only screen and (max-width: 900px) {
    .fixed-footer-share {
        display: none;
    }
}

/* form */

.form {
    width: 100%;
    position: relative;
    display: none;
}

.form.active {
    display: block;
}

.form-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    background: var(--shade-grey-t5);
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1440px;
    height: 1000px;
    position: fixed;
    z-index: 1000;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--shade-white-2);
}

.form-container > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 240px;
}

.form-close-button {
    width: 30px;
    height: 30px;
    position: fixed;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.form-close-button > img {
    width: 100%;
    height: 100%;
}

.form-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    color: black;
    font-size: 48px;
    font-weight: bold;
}

.form-details-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 800px;
    /* margin-top: 50px; */
    /* border: 3px red solid; */
    font-size: 32px;
}

.form-counter {
    position: absolute;
    top: 55px;
    right: 0px;
}

.form-step-buttons {
    position: absolute;
    width: 75%;
    bottom: 160px;
}

.form-step-buttons > button {
    position: absolute;
    width: 200px;
    height: 50px;
    font-size: 32px;
    color: black;
    background-color: var(--shade-grey-t2);
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 40px;
    cursor: pointer;
}

.form-step-back {
    left: 0;
}

.form-step-next {
    right: 0;
}

.form-input-wrapper {
    display: flex;
    flex-direction: column;
}

.form-input-wrapper label {
    margin-top: 50px;
}

.form-input-wrapper input {
    margin-top: 10px;
    font-size: 28px;
}

.form-input-select {
    font-size: 28px;
}

.form-input-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.form-input-whatsapp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.form-input-whatsapp > input:last-child {
    width: 100%
}

.form-input-country-code {
    text-align: center;
    width: 80px;
}

@media only screen and (max-width: 1600px) {
    .form-container {
        width: 80%;
    }
}

@media only screen and (max-width: 1150px) {
    .form-container {
        height: 800px;
    }

    .form-container > form {
        margin-top: 40px;
    }

    .form-title {
        align-items: flex-end;
        height: 90px;
        font-size: 32px;
        margin-bottom: 20px;
    }

    .form-counter {
        position: relative;
        font-size: 20px;
        margin: -30px auto 20px;
    }

    .form-step-buttons {
        bottom: 100px;
    }

    .form-step-buttons > button {
        width: 100px;
        height: 50px;
        font-size: 24px;
    }

    .form-input-wrapper label {
        margin-top: 30px;
        font-size: 20px;
    }
    
    .form-input-wrapper input {
        margin-top: 10px;
        font-size: 20px;
    }

    .form-input-select {
        font-size: 20px;
    }
    
    .form-input-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0px;
    }

    .form-input-whatsapp > input:last-child {
        width: 100%
    }
    
    .form-input-country-code {
        text-align: center;
        width: 80px;
    }
}

@media only screen and (max-height: 1200px) {
    .form-container {
        height: 80vh;
    }

    .form-container > form {
        overflow-y: scroll;
    }

    .form-title {
        font-size: 32px;
    }

    .form-counter {
        font-size: 20px;
    }

    .form-step-buttons {
        bottom: 100px;
    }

    .form-step-buttons > button {
        width: 100px;
        height: 50px;
        font-size: 24px;
    }

    .form-input-wrapper label {
        margin-top: 30px;
        font-size: 20px;
    }
    
    .form-input-wrapper input {
        margin-top: 10px;
        font-size: 20px;
    }

    .form-input-select {
        font-size: 20px;
    }
    
    .form-input-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0px;
    }

    .form-input-whatsapp > input:last-child {
        width: 100%
    }
    
    .form-input-country-code {
        text-align: center;
        width: 80px;
    }
}

@media only screen and (max-height: 1200px) and (max-width: 768px) {
    .form-container > form {
        margin-bottom: 160px;
    }
}

/* ibdp */

.ibdp-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 750px; */
    margin-top: 50px;
    padding: 50px 0px;
    position: relative;
}

.ibdp-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1440px;
    margin: 0px auto;
}

.ibdp-profile-title {
    text-align: center;
    color: black;
    font-size: 64px;
    font-weight: bold;
    /* margin-top: 30px; */
}

.ibdp-profile-textdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 60px;
}

.ibdp-profile-description {
    width: 1440px;
    padding: 0px 25px;
}

@media only screen and (max-width: 1600px) {
    .ibdp-profile {
        width: 100%;
        margin-top: 50px;
        padding: 50px 0px;
    }
    
    .ibdp-profile-container {
        width: 100%;
        margin: 0px auto;
    }
    
    .ibdp-profile-title {
        font-size: 48px;
    }
    
    .ibdp-profile-textdiv {
        width: 100%;
        margin-top: 60px;
    }
    
    .ibdp-profile-description {
        width: 100%;
        padding: 0px 25px;
    }
}

@media only screen and (max-width: 768px) {
    .ibdp-profile-title {
        font-size: 48px;
    }

    .ibdp-profile-title span {
        display: none;
    }
}

/* bkgs */

.background-pink {
    background: var(--grad-pink);
}

.background-blue {
    background: var(--grad-blue);
}

.background-purple {
    background: var(--grad-purple);
}

.background-orange {
    background: var(--grad-orange);
}

.background-red {
    background-color: var(--shade-red);
}

/* shades */

.shade-orange-2 {
    background-color: var(--shade-orange-2);
}

.shade-pink-1 {
    background-color: var(--shade-pink-1);
}

.shade-blue-1 {
    background-color: var(--shade-blue-1);
}

/* mobile stuff */

.not-mobile {
    display: block;
}

.mobile-hidden-header {
    display: flex;
}

.mobile-header-links {
    display: none;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-family: "Lato";
    padding: 0px 15px;
    width: 100px;
    height: 60px;
    cursor: pointer;
}

.mobile-header-links > div {
    margin: 0px 10px;
}

.mobile-header-links > img {
    width: 25px;
    height: auto;
    margin: auto;
    transition: all 0.7s ease-in-out;
}

.mobile-header-links > img.open {
    transform:
        rotateZ(-90deg);
}

@media only screen and (max-width: 768px) {
    .mobile-hidden-header {
        display: none;
    }

    .mobile-hidden-header.open {
        display: flex;
    }

    .mobile-header-links {
        display: flex;
    }
}

@media only screen and (max-width: 768px) {
    .not-mobile {
        display: none;
    }
}